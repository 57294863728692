/*    
<summary>
   This typescript ts file contain all the formik form validations.
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
*/
import * as Yup from "yup";
import config from "../../helpers/config-helper";

const appConfig = config();

const macAddressRegex = /^([0-9A-Fa-f]{2}([-:])){5}[0-9A-Fa-f]{2}$/;
const ipv4Regex =
  /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;
const ipv6Regex =
  /^(([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)|(([0-9a-fA-F]{1,4}:){1,6}|:):([0-9a-fA-F]{1,4}|:){1,5}([0-9a-fA-F]{1,4}|:)|::)$/;

const ipRegex = new RegExp(`(${ipv4Regex.source})|(${ipv6Regex.source})`);

export const ChangeLoggedInUserPasswordValidateSchema = Yup.object({
  OldPassword: Yup.string()
    .min(6, "user_validation_password_min_char")
    .max(20, "user_validation_password_max_char")
    .required("validation_required_current_password"),
  NewPassword: Yup.string()
    .min(6, "user_validation_password_min_char")
    .max(20, "user_validation_password_max_char")
    .required("change_password_validation_new_required_password"),
});

export const UserChangePasswordValidateSchema = Yup.object({
  NewPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "user_validation_password_matches"
    )
    .required("field_required"),
});

export const LoginValidateSchema = Yup.object({
  email: Yup.string().trim().required("field_required"),
  password: Yup.string().trim().required("field_required"),
});
export const AddUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .min(5, "min_user_name_length_required")
    .max(25, "min_user_name_length_required")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  Password: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "user_validation_password_matches"
    )
    .required("field_required"),
  Email: Yup.string()
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/,
      "invalid_login_id"
    )
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  UserType: Yup.number().min(0, "field_required"),
});

export const EditUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .required("field_required")
    .min(5, "min_user_name_length_required")
    .max(25, "min_user_name_length_required"),
  Email: Yup.string()
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/,
      "invalid_login_id"
    )
    .min(5, "login_id_length_required")
    .max(50, "login_id_length_required")
    .required("field_required"),
  UserType: Yup.number().min(0, "field_required"),
});

export const AddSystemUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .min(5, "min_user_name_length_required")
    .max(25, "min_user_name_length_required")
    .required("field_required"),
  Password: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "user_validation_password_matches"
    )
    .required("field_required"),
  Email: Yup.string()
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/,
      "invalid_login_id"
    )
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  TenantId: Yup.number().min(0, "field_required"),
  UserType: Yup.number().min(0, "field_required"),
});

export const AddImageMediaValidateSchema = Yup.object({
  ImageName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
});

export const UpdateImageMediaValidateSchema = Yup.object({
  Height: Yup.number()
    .min(0, "height_max_min")
    .max(20000, "height_max_min")
    .required("field_required"),
  Width: Yup.number()
    .min(0, "width_max_min")
    .max(20000, "width_max_min")
    .required("field_required"),
});

export const UpdateImagePricingMediaValidateSchema = Yup.object({
  PricingValue: Yup.number()
    .typeError("only_digits")
    .min(0, "pricing_range")
    .max(9, "pricing_range")
    .required("field_required"),
});

export const EmptyValidateSchema = Yup.object({});

export const UpdateVideoValidateSchema = Yup.object({
  Height: Yup.number()
    .min(0, "height_max_min")
    .max(20000, "height_max_min")
    .required("field_required"),
  Width: Yup.number()
    .min(0, "width_max_min")
    .max(20000, "width_max_min")
    .required("field_required"),
});

export const DigitMappingValidateSchema = Yup.object({
  DataNumber: Yup.array().of(
    Yup.object().shape({
      FolderId: Yup.number(),
      Height: Yup.number()
        .min(0, "height_max_min")
        .max(20000, "height_max_min")
        .when("FolderId", (FolderId, schema) => {
          if (FolderId[0] > 0) {
            return schema.required("field_required");
          } else {
            return schema.notRequired();
          }
        }),
      // Height: Yup.number()
      // .min(0, "height_max_min").max(20000, "height_max_min")
      // .required("field_required"),
      Width: Yup.number()
        .min(0, "width_max_min")
        .max(20000, "width_max_min")
        .when("FolderId", (FolderId, schema) => {
          if (FolderId[0] > 0) {
            return schema.required("field_required");
          } else {
            return schema.notRequired();
          }
        }),
    })
  ),
});

export const uploadImageSchema = Yup.object({
  ImageFile: Yup.string().required("field_required"),
});

export const AddPriceSchedulerSchema = Yup.object({
  Weekday: Yup.number().min(0, "field_required"),
  Data1: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data2: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data3: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data4: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data5: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data6: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
});

export const AddSpecialSchedulerSchema = Yup.object({
  ScheduleType: Yup.number().min(0, "field_required"),
  StartDate: Yup.string().required("field_required"),
  EndDate: Yup.string().required("field_required"),
  StartTime: Yup.string().required("field_required"),
  EndTime: Yup.string().required("field_required"),
  Data1: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data2: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data3: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data4: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data5: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
  Data6: Yup.string()
    .matches(/^[0-9\s]*$/, "only_digits")
    .test("maxDigits", "data_digits_valid", (value) => {
      if (value === undefined) {
        return true;
      }
      return value.toString().length <= 4;
    }),
});

export const AddParkingLotStatusImageSchema = Yup.object({
  Status: Yup.number().required("field_required").min(0, "field_required"),
  FolderId: Yup.number().required("field_required").min(0, "field_required"),
  Image: Yup.number().required("field_required").min(0, "field_required"),
});

export const AddVideoMediaValidateSchema = Yup.object({
  VideoName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
  // IsDefaultSettings: Yup.boolean()
  // .required("field_required"),
});

export const setThresholdValidationSchema = Yup.object().shape({
  CategoryCounts: Yup.array().of(
    Yup.object()
      .shape({
        fullAfter: Yup.number()
          .required("field_required")
          .min(0, "Full must be at least 0"),
        availUpto: Yup.number()
          .required("field_required")
          .min(0, "Available must be at least 0"),
        CategoryId: Yup.number().required(),
        Count: Yup.number().required().min(0, "Count must be at least 0"),
      })
      .test(
        "sum-check",
        "Sum of full and available should not exceed the count",
        function (value) {
          const { fullAfter, availUpto, Count, CategoryId } = value;
          let availCount = availUpto - 1;
          let crowdedCount = availUpto - fullAfter + 1;
          let fullCount = Count - fullAfter;

          if (fullAfter > Count) {
            return this.createError({
              path: `${this.path}.fullAfter`,
              message: `full_cannot_greater_count`,
            });
          }

          if (availUpto > Count) {
            return this.createError({
              path: `${this.path}.availUpto`,
              message: `available_cannot_greater_count`,
            });
          }

          if (availUpto > fullAfter) {
            return this.createError({
              path: `${this.path}.availUpto`, // set the error on the specific path
              message: `available_cannot_greater_full`,
            });
          }

          if (availCount + Math.abs(crowdedCount) + fullCount > Count) {
            return this.createError({
              path: `${this.path}.availUpto`,
              message: `threshold_incorrect`,
            });
          }
          return true;
        }
      )
  ),
});

export const LinkbaseInValidationSchema = Yup.object().shape({
  LinkBaseInType: Yup.number()
    .min(0, "field_required")
    .required("field_required"),
  SimNumber: Yup.string()
    .required("field_required")
    .max(30, "iccid_length_required")
    .test("must_greater-than-0", "sim_number_min", function (SimNumber) {
      if (SimNumber)
        return SimNumber ? (BigInt(SimNumber) > 0 ? true : false) : false;
      else return true;
    }),
});

export const CameraValidationSchema = (id: number, isImageChange: boolean) =>
  Yup.object().shape({
    CameraName: Yup.string()
      .max(20, "char_limit_reached")
      .required("field_required"),
    MacAddress: Yup.string()
      .matches(macAddressRegex, "invalid_mac_address")
      .required("field_required"),
    IpAddress: Yup.string().matches(ipRegex, "invalid_ip_address"),
    FolderId: Yup.number().when([], {
      is: () => id < 0 || isImageChange,
      then: (schema) =>
        schema.min(0, "field_required").required("field_required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    Height: Yup.number()
      .min(0, "height_max_min")
      .max(20000, "height_max_min")
      .required("field_required"),
    Width: Yup.number()
      .min(0, "width_max_min")
      .max(20000, "width_max_min")
      .required("field_required"),
    FullImage: Yup.number().when([], {
      is: () => id < 0 || isImageChange,
      then: (schema) =>
        schema.min(0, "field_required").required("field_required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    AvailImage: Yup.number().when([], {
      is: () => id < 0 || isImageChange,
      then: (schema) =>
        schema.min(0, "field_required").required("field_required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    ClosedImage: Yup.number().when([], {
      is: () => id < 0 || isImageChange,
      then: (schema) =>
        schema.min(0, "field_required").required("field_required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isSlotEnable1: Yup.number().min(0, "field_required"),
    isSlotEnable2: Yup.number().min(0, "field_required"),
    isSlotEnable3: Yup.number().min(0, "field_required"),
    isSlotEnable4: Yup.number().min(0, "field_required"),
    isSlotEnable5: Yup.number().min(0, "field_required"),
    isSlotEnable6: Yup.number().min(0, "field_required"),
    slotType_1: Yup.number().when("isSlotEnable1", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_2: Yup.number().when("isSlotEnable2", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_3: Yup.number().when("isSlotEnable3", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_4: Yup.number().when("isSlotEnable4", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_5: Yup.number().when("isSlotEnable5", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
    slotType_6: Yup.number().when("isSlotEnable6", {
      is: 1,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.min(0, "field_required").required("field_required"),
    }),
  });

export const UploadImageMediaValidateSchema = Yup.object({
  File: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("File", "only_image_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg"
        );
      } else return false;
    }),
});

export const SignBoardImageMediaValidateSchema = Yup.object({
  ImageFile: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("File", "only_image_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg"
        );
      } else return false;
    }),
});

export const UploadVideoMediaValidateSchema = Yup.object({
  VideoFile: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_video_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return val[1].toLowerCase() === "mp4";
      } else return false;
    }),
});

export const EditSystemUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .required("field_required")
    .min(5, "min_user_name_length_required")
    .max(25, "min_user_name_length_required"),
  Email: Yup.string()
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/,
      "invalid_login_id"
    )
    .min(5, "login_id_length_required")
    .max(50, "login_id_length_required")
    .required("field_required"),
  UserType: Yup.number().min(0, "field_required"),
});

export const tenantDetailValidateSchema = Yup.object({
  OrganizationId: Yup.string()
    .required("field_required")
    .matches(/^[A-Za-z][A-Za-z0-9]*$/, "invalid_input")
    .max(8, "char_limit_reached_org_id"),
  OrganizationName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
});

export const AddBranchValidateSchema = Yup.object({
  BranchName: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  Comment: Yup.string().max(500, "comment_limit_reach"),
});

export const AddSoracomValidateSchema = Yup.object({
  OperatorId: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  UserName: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  Password: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    // .matches(
    //   /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
    //   "user_validation_password_matches"
    // )
    .required("field_required"),
});

export const AddParkingLotDetailsValidateSchema = Yup.object({
  BranchId: Yup.number().min(1, "field_required"),
  CurrentStatus: Yup.number().min(0, "field_required"),
  PlName: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  ParkingLotId: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  Comment: Yup.string().max(500, "comment_limit_reach"),
});

export const AddParkingLotCategoryDetailsValidateSchema = Yup.object().shape({
  ParkingLotsCategories: Yup.array().of(
    Yup.object().shape({
      Description: Yup.string()
        .max(20, "char_limit_reached")
        .required("field_required")
        .test("no-leading-whitespace", "whitespace_error", (value) => {
          if (typeof value !== "string") return false;
          return !value.startsWith(" ");
        }),
    })
  ),
});

export const AddMediaDefaultFolderValidateSchema = Yup.object({
  FolderName: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  FolderCategoryType: Yup.number()
    .min(0, "field_required")
    .required("field_required"),
  Comment: Yup.string().max(500, "comment_limit_reach"),
});

export const selectCSVValidateSchema = Yup.object({
  CSVFile: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_csv", (value: any) => {
      let val: any;
      if (value) {
        val = value.split(".");
        return val[1].toLowerCase() === "csv";
      } else return false;
    }),
});

export const AddMediaFolderValidateSchema = Yup.object({
  FolderName: Yup.string()
    .max(20, "char_limit_reached")
    .required("field_required")
    .test("no-leading-whitespace", "whitespace_error", (value) => {
      if (typeof value !== "string") return false;
      return !value.startsWith(" ");
    }),
  LeftMargin: Yup.string()
    .required("field_required")
    .matches(/^[0-9-]+$/, "only_digits"),
  TopMargin: Yup.string()
    .required("field_required")
    .matches(/^[0-9-]+$/, "only_digits"),
  FolderCategoryType: Yup.number()
    .min(0, "field_required")
    .required("field_required"),
  Comment: Yup.string().max(500, "comment_limit_reach"),
});

export const AddParkingLotLinkBaseValidateSchema = Yup.object({
  ParkingLotInputTypeEnum: Yup.number().min(0, "field_required"),
  ParkingLotOutputTypeEnum: Yup.number()
    .min(0, "field_required")
    .test(
      "ParkingLotInputTypeEnum",
      "linkbase_in_out_validation",
      function (value) {
        if (
          this.parent.ParkingLotInputTypeEnum == 2 &&
          this.parent.ParkingLotOutputTypeEnum == 1
        ) {
          return false;
        }
        return true;
      }
    ),
});

export const AddMediaPriceValidateSchema = Yup.object({
  MediaName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
  PricingValue: Yup.number()
    .typeError("only_digits")
    .min(0, "pricing_range")
    .max(9, "pricing_range")
    .required("field_required"),
});

export const AddMediaHeightWidthalidateSchema = Yup.object({
  MediaName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
  Height: Yup.number()
    .min(0, "height_max_min")
    .max(20000, "height_max_min")
    .required("field_required"),
  Width: Yup.number()
    .min(0, "width_max_min")
    .max(20000, "width_max_min")
    .required("field_required"),
});

export const AddMediaValidateSchema = Yup.object({
  MediaName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
});

export const AddLinkbaseOutValidationSchema = Yup.object({
  SoracomId: Yup.number().min(1, "field_required").required("field_required"),
  RelayType: Yup.number().min(0, "field_required").required("field_required"),
  SimId: Yup.string()
    .required("field_required")
    .max(30, "iccid_length_required")
    .test("must_greater-than-0", "sim_number_min", function (SimId) {
      if (SimId) return SimId ? (BigInt(SimId) > 0 ? true : false) : false;
      else return true;
    }),
});

export const AddLinkbaseOutDetailsValidationSchema = Yup.object({
  LinkBaseOutRelays: Yup.array().of(
    Yup.object().shape({
      TargetType: Yup.number().test(
        "TargetType",
        "field_required_target_type",
        (value, context) => {
          let involvedPaths = [
            "LinkBaseOutRelays[0].TargetType",
            "LinkBaseOutRelays[4].TargetType",
            "LinkBaseOutRelays[8].TargetType",
            "LinkBaseOutRelays[10].TargetType",
          ];
          if (involvedPaths.includes(context.path)) {
            if (Number(value) === -1 && context.parent?.Disabled === false) {
              return false;
            }
          }
          return true;
        }
      ),
      ParkingLotTarget: Yup.string().test(
        "ParkingLotTarget",
        "field_required_parking_lot",
        (value, context) => {
          let involvedPaths = [
            "LinkBaseOutRelays[0].ParkingLotTarget",
            "LinkBaseOutRelays[4].ParkingLotTarget",
            "LinkBaseOutRelays[8].ParkingLotTarget",
            "LinkBaseOutRelays[10].ParkingLotTarget",
          ];
          if (involvedPaths.includes(context.path)) {
            if (
              Number(context.parent?.TargetType) === 0 &&
              Number(value) === -1
            ) {
              return false;
            }
          }
          return true;
        }
      ),
      SlotTarget: Yup.string().test(
        "SlotTarget",
        "field_required_slot",
        (value, context) => {
          let involvedPaths = [
            "LinkBaseOutRelays[0].SlotTarget",
            "LinkBaseOutRelays[4].SlotTarget",
            "LinkBaseOutRelays[8].SlotTarget",
            "LinkBaseOutRelays[10].SlotTarget",
          ];
          if (involvedPaths.includes(context.path)) {
            if (
              Number(context.parent?.TargetType) === 1 &&
              Number(value) === -1
            ) {
              return false;
            }
          }
          return true;
        }
      ),
      SlotTypeTarget: Yup.string().test(
        "SlotTypeTarget",
        "field_required_slot_type",
        (value, context) => {
          let involvedPaths = [
            "LinkBaseOutRelays[0].SlotTypeTarget",
            "LinkBaseOutRelays[4].SlotTypeTarget",
            "LinkBaseOutRelays[8].SlotTypeTarget",
            "LinkBaseOutRelays[10].SlotTypeTarget",
          ];
          if (involvedPaths.includes(context.path)) {
            if (
              Number(context.parent?.TargetType) === 2 &&
              Number(value) === -1
            ) {
              return false;
            }
          }
          return true;
        }
      ),
      Comment: Yup.string().test(
        "Comment",
        "char_limit_reached_comment",
        (value, context) => {
          if (
            context.parent?.Comment?.length > 56 &&
            context.parent?.Disabled == false
          ) {
            return false;
          }
          return true;
        }
      ),
    })
  ),
});

export const AddIntegratorLinkbaseOutDetailsValidationSchema = Yup.object({
  LinkBaseOutRelays: Yup.array().of(
    Yup.object().shape({
      Target: Yup.number()
        .nullable()
        .test("Target", "field_required", function (value, contexts) {
          let involvedPaths = [
            "LinkBaseOutRelays[0].Target",
            "LinkBaseOutRelays[4].Target",
            "LinkBaseOutRelays[8].Target",
            "LinkBaseOutRelays[10].Target",
          ];
          const { Disabled } = this.parent;
          if (involvedPaths.includes(contexts.path)) {
            if (Disabled == true && this.originalValue === undefined) {
              return true;
            }

            if (
              Disabled == false &&
              (this.originalValue === undefined ||
                this.originalValue === "-1" ||
                this.originalValue === -1)
            ) {
              return false;
            }
          }
          return true;
        }),
      Comment: Yup.string().test(
        "Comment",
        "char_limit_reached_comment",
        (value, context) => {
          if (
            context.parent?.Comment?.length > 56 &&
            context.parent?.Disabled == false
          ) {
            return false;
          }
          return true;
        }
      ),
    })
  ),
});

export const AddMediaFileValidateSchema = Yup.object({
  File: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
});

export const AddPricingMediaImageFileValidateSchema = Yup.object({
  File0: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    File1: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    File2: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    File3: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    File4: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    File5: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    File6: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    File7: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    File8: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    File9: Yup.mixed()
    .nullable()
    .required("field_required")
    .test("csv", "only_media_files", (value: any) => {
      let val;
      if (value) {
        val = value?.split(".");
        return (
          val[1].toLowerCase() === "jpg" ||
          val[1].toLowerCase() === "jpeg" ||
          val[1].toLowerCase() === "png" ||
          val[1].toLowerCase() === "svg" ||
          val[1].toLowerCase() === "mp4"
        );
      } else return false;
    }),
    
});

export const AddIntegratorValidateSchema = Yup.object({
  IntegratorId: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
  IntegratorName: Yup.string()
    .required("field_required")
    .max(20, "char_limit_reached"),
});
