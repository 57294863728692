/*    
<summary>
   This class component is all about Managing Branch functionality.
   Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import * as baseService from "../service/base-service";
import { initialState as allCameraInitialState } from "../initialState/get-all-camera-state";
import { initialState as addCameraInitialState } from "../initialState/add-edit-camera-state";
import { initialState as addImageInitialState } from "../initialState/add-edit-image-status-state";
import toast from "react-hot-toast";
import URLConstants from "../../constants/url-constants";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import { IBranchVM } from "../../models/response/IBranchResponse";
import { ICameraState } from "../../models/state/ICameraState";
import {
  ICameraList,
  ICameraListVM,
  ICameraSlotCountVM,
  ICameraVM,
  ISlotListVM,
  ICameraSlotVM,
  ISlotList,
  ICameraSlotPanelVM,
} from "../../models/response/ICameraResponse";
import IAddCamera, {
  ISetParkingLotStatusImage,
} from "../../models/forms/IAddUpdateCamera";
import { slotStatus } from "../../constants/enums/slot-status-enum";
import categoryEnum from "../../constants/enums/category-enum";
import { useStore } from "../../contexts/store-provider";
import { initialState as slotState } from "../initialState/get-all-camera-slot-list";
import { parkingLotStatus } from "../../constants/enums/parking-lot-status-enum";

export class CameraStore implements ICameraState, ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  allCameraSlots: any = undefined;

  slotList: any = undefined;
  cameraList: ICameraListVM = allCameraInitialState;
  allCameras: ICameraVM[] = [];

  cameraSlotStatus: any = undefined;
  getCameraSlotStatusState = { ...this.initialStateValue };

  getCameraSlotListStatus = { ...this.initialStateValue };

  cameraSlot: any = undefined;
  getCameraSlotById = { ...this.initialStateValue };

  camera: any = undefined;
  addUpdateCameraState = { ...this.initialStateValue };
  allCameraState = { ...this.initialStateValue };
  deleteCameraState = { ...this.initialStateValue };
  cameraState = { ...this.initialStateValue };
  changePasswordState = { ...this.initialStateValue };

  parkingLotStatusImage: any = undefined;
  setParkingLotStatusImageState = { ...this.initialStateValue };
  updateParkingLotStatusImageState = { ...this.initialStateValue };
  statusImageByIdState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      slotList: observable,
      cameraList: observable,
      allCameraSlots: observable,
      cameraSlotStatus: observable,
      addUpdateCameraState: observable,
      deleteCameraState: observable,
      cameraState: observable,
      camera: observable,
      cameraSlot: observable,
      getCameraSlotById: observable,
      getCameraSlotListStatus: observable,
      allCameras: observable,
      allCameraState: observable,
      setParkingLotStatusImageState: observable,
      updateParkingLotStatusImageState: observable,
      statusImageByIdState: observable,
      parkingLotStatusImage: observable,

      AddCameraService: action,
      UpdateCameraService: action,
      GetCameraSlotsStatusByTenantId: action,
      GetAllCameras: action,
      GetCameraListService: action,
      GetCameraService: action,
      GetAllCameraSlotsListService: action,
      GetCameraSlotByIdService: action,
      SetParkingLotStatusImage: action,
      UpdateParkingLotStatusImageService: action,
      GetParkingLotStatusImageById: action,
      GetAllCameraSlotsService: action,
      DeleteCameraService: action,
      resetGetCameraDetail: action,
      resetDeleteCameraState: action,
      resetAddUpdateCameraState: action,
      resetParkingLotStatusImageDetail: action,
      resetSetParkingLotStatusImageState: action,
      resetUpdateParkingLotStatusImageState: action,
      resetParkingLotSlotList: action,
      resetCameraSlotCount:action,
      resetStore: action,
      reset: action,
      allCameraList: computed,
      cameraDetails: computed,
      statusImageDetails: computed,
      cameraSlotStatusCount: computed,
      cameraSlotListDetails: computed,
      allAvailableCameraSlots: computed
    });
  }

  /**
   * This function is used to get tenants list with pagination by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetCameraListService = (
    pageNumber: number,
    pageSize: number,
    tenantId: number,
    plId: number
  ) => {
    this.inProgress = true;
    const url =
      URLConstants.GetCameraList +
      "/" +
      "?PageNo=" +
      pageNumber +
      "&PageSize=" +
      pageSize +
      "&tenantId=" +
      tenantId +
      "&plid=" +
      plId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<ICameraListVM>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.cameraList = response.data.Data;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  /**
   * This function is used to get Camera Slots with there status using Tenant Id by calling API.
   * @param pageNumber : Page Number
   * @param pageSize : Page Size
   * @returns
   */
  GetCameraSlotsStatusByTenantId = (plId: number, tenantId: number) => {
    this.getCameraSlotStatusState.inProgress = true;
    const url =
      URLConstants.GetCameraSlotCountById +
      "?plid=" +
      plId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<ICameraSlotCountVM>>) => {
          if (response.data.Error) {
            this.getCameraSlotStatusState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.cameraSlotStatus = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getCameraSlotStatusState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map Camera Slot Count suitable for Grid Component.
   * @returns Initial Camera Slot
   */
  get cameraSlotStatusCount(): any {
    let obj = {
      TotalSlotsCount: 0,
      CategoryCounts: [],
      ParkingLotsCategoriesVM: [],
    };
    let categoryCount: any = [];
    if (
      this.cameraSlotStatus &&
      this.cameraSlotStatus?.CategoryCounts.length > 0
    ) {
      this.cameraSlotStatus?.CategoryCounts.map((data: any, index: number) => {
        categoryCount.push({
          CategoryId: data.Category,
          Count:
            data.Category == categoryEnum.Overall
              ? this.cameraSlotStatus.TotalSlotsCount
              : data.Count,
          availUpto:
            data.Threshold?.length > 0 ? data.Threshold[0].AvailUpto : 0,
          fullAfter:
            data.Threshold?.length > 0 ? data.Threshold[0].FullAfter : 0,
          ThresholdId:
            data.Threshold?.length > 0 ? data.Threshold[0].ThresholdId : 0,
        });
      });
      obj.CategoryCounts = categoryCount;
      obj.ParkingLotsCategoriesVM = this.cameraSlotStatus?.ParkingLotsCategoriesVM;
      obj.TotalSlotsCount = this.cameraSlotStatus.TotalSlotsCount;
    }
    return obj;
  }

  /**
   * This function is used to Get Camera Details by calling an API.
   * @param cameraId : Camera Identifier
   * @returns
   */
  GetCameraSlotByIdService = (cameraId: number, tenantId: number) => {
    this.getCameraSlotById.inProgress = true;
    const url =
      URLConstants.GetCameraSlotById +
      "?cameraid=" +
      cameraId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getCameraSlotById.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.cameraSlot = response.data.Data;
          this.getCameraSlotById.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getCameraSlotById.inProgress = false;
        })
      );
  };

  GetAllCameraSlotsService = (tenantId: number, plId: number) => {
    this.getCameraSlotListStatus.inProgress = true;
    const url =
      URLConstants.GetAllCameraSlots + "?tenantId=" +tenantId+"&plid="+plId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getCameraSlotListStatus.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allCameraSlots = response.data.Data;
          this.getCameraSlotListStatus.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getCameraSlotListStatus.inProgress = false;
        })
      );
  };

  get allAvailableCameraSlots(): any[] {
      const cameraSlotOption: IOption[] = [
        {
          id: -1,
          value: "please_select",
          disabled: false,
        },
      ];
      if (this.allCameraSlots && this.allCameraSlots?.length > 0)
        this.allCameraSlots.map((cameraSlot:any) => {
          cameraSlotOption.push({
            id: cameraSlot.SlotId,
            value: cameraSlot.MacAddress +" / No:" + cameraSlot.SlotAbsoluteNumber,
            disabled: false,
          });
        });
      return cameraSlotOption;
  }

  /* This function is used to show camera slots list. */
  GetAllCameraSlotsListService = (cameraId: number, tenantId: number) => {
    this.getCameraSlotListStatus.inProgress = true;
    const url =
      URLConstants.GetCameraSlotById +
      "?cameraid=" +
      cameraId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getCameraSlotListStatus.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.slotList = response.data.Data;
          this.getCameraSlotListStatus.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getCameraSlotListStatus.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map cameraDataList to allCamerasList suitable for Grid Component.
   * @returns Initial Camera Details
   */
  get allCameraList(): ICameraList[] {
    if (this.cameraList && this.cameraList?.Camera.length > 0) {
      return this.cameraList?.Camera.map((data) => {
        let isDelete = true;
        let isEdit = true;
        return {
          CameraId: data.CameraId,
          CameraName: data.CameraName,
          ParkingLot: data.ParkingLot,
          MacAddress: data.MacAddress,
          IpAddress: data.IpAddress,
          Comment: data.Comment,
          SlotsCount: data.SlotsCount,
          isEdit: isEdit,
          isDelete: isDelete,
        };
      });
    }
    return [];
  }

  get cameraSlotListDetails(): ICameraSlotPanelVM[] {
    if (this.slotList && this.slotList.length > 0) {
      return this.slotList.map((data: any, index: number) => {
        return {
          CameraId: data.CameraId,
          SlotId: data.SlotId,
          CategoryId: data.Category,
          Status: data.Status,
          SlotAbsoluteNumber: data.SlotAbsoluteNumber,
          ParkingStatus: parkingLotStatus[data.ParkingStatus]
        };
      });
    }
    return [];
  }

  /**
   * This function is used to Get Camera Details by calling an API.
   * @param cameraId : Camera Identifier
   * @returns
   */
  GetCameraService = (cameraId: number, tenantId: number) => {
    this.cameraState.inProgress = true;
    const url =
      URLConstants.GetCameraById +
      "?cameraid=" +
      cameraId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.cameraState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.camera = response.data.Data;
          this.cameraState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.cameraState.inProgress = false;
        })
      );
  };

  GetAllCameras = (tenantId: any) => {
    this.allCameraState.inProgress = true;
    const url = URLConstants.GetAllCamera + "?tenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.allCameraState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allCameras = response.data.Data;
          this.allCameraState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.allCameraState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map available group List for Add Device Component with AwsIoTCore Configuration.
   * @returns Initial Group Details
   */
  get allAvailableCameras(): IOption[] {
    const cameraOptions: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: false,
      },
    ];
    if (this.allCameras && this.allCameras?.length > 0)
      this.allCameras.map((camera) => {
        cameraOptions.push({
          id: camera.CameraId,
          value: camera.CameraName,
          disabled: false,
        });
      });
    return cameraOptions;
  }

  /**
   * This function provides initail values to the Add Update Device Form.
   * @returns Initial Device Details
   */
  get cameraDetails(): any {
    if (this.camera && this.cameraSlot && this.cameraSlot.length > 0) {
      let modifiedStr = this.cameraSlot[0]?.ImageStyle?.replace(/\s+/g, "")
        ?.replace(/[{}]/g, "")
        ?.split(";");
      let marginTop = modifiedStr[0]?.split(":");
      let marginBottom = modifiedStr[1]?.split(":");
      let marginLeft = modifiedStr[2]?.split(":");
      let marginRight = modifiedStr[3]?.split(":");
      let orgId =
        localStorage.getItem("hips_access_org_id") !== null
          ? localStorage.getItem("hips_access_org_id")
          : "null";
      return {
        CameraId: this.camera.CameraId,
        CameraName: this.camera.CameraName,
        ParkingLot: this.camera.ParkingLot,
        MacAddress: this.camera.MacAddress,
        IpAddress: this.camera.IpAddress,
        Height: this.camera.Height,
        Width: this.camera.Width,
        Comment: this.camera.Comment,

        FolderId: -1,
        AvailImage: -1,
        FullImage: -1,
        ClosedImage: -1,

        AvailImageBase64: this.cameraSlot[0]?.AvailImage,
        FullImageBase64: this.cameraSlot[0]?.FullImage,
        ClosedImageBase64: this.cameraSlot[0]?.ClosedImage,

        MediaSetting: this.cameraSlot[0]?.ImageStyle,

        isSlotEnable1: slotStatus[this.cameraSlot[0]?.Status],
        isSlotEnable2: slotStatus[this.cameraSlot[1]?.Status],
        isSlotEnable3: slotStatus[this.cameraSlot[2]?.Status],
        isSlotEnable4: slotStatus[this.cameraSlot[3]?.Status],
        isSlotEnable5: slotStatus[this.cameraSlot[4]?.Status],
        isSlotEnable6: slotStatus[this.cameraSlot[5]?.Status],

        slotId_1: this.cameraSlot[0]?.SlotId,
        slotId_2: this.cameraSlot[1]?.SlotId,
        slotId_3: this.cameraSlot[2]?.SlotId,
        slotId_4: this.cameraSlot[3]?.SlotId,
        slotId_5: this.cameraSlot[4]?.SlotId,
        slotId_6: this.cameraSlot[5]?.SlotId,

        slotType_1: this.cameraSlot[0]?.Category == 0 ? -1 : this.cameraSlot[0]?.Category,
        slotType_2: this.cameraSlot[1]?.Category == 0 ? -1 : this.cameraSlot[1]?.Category,
        slotType_3: this.cameraSlot[2]?.Category == 0 ? -1 : this.cameraSlot[2]?.Category,
        slotType_4: this.cameraSlot[3]?.Category == 0 ? -1 : this.cameraSlot[3]?.Category,
        slotType_5: this.cameraSlot[4]?.Category == 0 ? -1 : this.cameraSlot[4]?.Category,
        slotType_6: this.cameraSlot[5]?.Category == 0 ? -1 : this.cameraSlot[5]?.Category,

        slotLink_1:`${process.env.REACT_APP_BASE_URL}Slot/${orgId?.toString()}/${this.cameraSlot[0]?.SlotId?.toString()}`,
        slotLink_2:`${process.env.REACT_APP_BASE_URL}Slot/${orgId?.toString()}/${this.cameraSlot[1]?.SlotId?.toString()}`,
        slotLink_3:`${process.env.REACT_APP_BASE_URL}Slot/${orgId?.toString()}/${this.cameraSlot[2]?.SlotId?.toString()}`,
        slotLink_4:`${process.env.REACT_APP_BASE_URL}Slot/${orgId?.toString()}/${this.cameraSlot[3]?.SlotId?.toString()}`,
        slotLink_5:`${process.env.REACT_APP_BASE_URL}Slot/${orgId?.toString()}/${this.cameraSlot[4]?.SlotId?.toString()}`,
        slotLink_6:`${process.env.REACT_APP_BASE_URL}Slot/${orgId?.toString()}/${this.cameraSlot[5]?.SlotId?.toString()}`,

        marginTop: Number(marginTop[1]?.replace("px","")),
        marginLeft: Number(marginLeft[1]?.replace("px","")),

        isChangeImage: false,
      };
    }
    return addCameraInitialState;
  }

  /**
   * This function is used to Add New Branch by calling an API & sending the required tenant details.
   * @param branch : Branch Details
   * @returns
   */
  AddCameraService = (camera: IAddCamera) => {
    this.addUpdateCameraState.inProgress = true;
    return baseService
      .postRequest(URLConstants.AddCamera, camera)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateCameraState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateCameraState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateCameraState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to update existing branch by calling an API & sending updated branch details.
   * @param id : Branch identifier
   * @param branch : Branch Details
   * @returns
   */
  UpdateCameraService = (camera: any) => {
    this.addUpdateCameraState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdateCamera, camera)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateCameraState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.addUpdateCameraState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.addUpdateCameraState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to delete existing Camera by calling an API.
   * @param id : Camera identifier
   * @returns
   */
  DeleteCameraService = (cameraId: number, tenantId: number) => {
    this.deleteCameraState.inProgress = true;
    const url =
      URLConstants.DeleteCamera +
      "?cameraid=" +
      cameraId +
      "&tenantId=" +
      tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteCameraState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.deleteCameraState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteCameraState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Set Parking Lot Status Image by calling an API & sending the required tenant details.
   * @param parkingLotStatus : Parking Lot Status Image Details
   * @returns
   */
  SetParkingLotStatusImage = (parkingLotStatus: any) => {
    this.setParkingLotStatusImageState.inProgress = true;
    return baseService
      .postRequest(URLConstants.SetParkingLotStatusImage, parkingLotStatus)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setParkingLotStatusImageState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.setParkingLotStatusImageState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.setParkingLotStatusImageState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to update existing Status Image Data by calling an API & sending updated Parking Lot details.
   * @param parkingLotStatus : ParkingLot Status Details
   * @returns
   */
  UpdateParkingLotStatusImageService = (
    parkingLotStatus: any
  ) => {
    this.updateParkingLotStatusImageState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdateParkingLotStatusImage, parkingLotStatus)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.updateParkingLotStatusImageState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.updateParkingLotStatusImageState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.updateParkingLotStatusImageState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Get Parking Lot Status Image By Id Details by calling an API.
   * @param imageId : Status Identifier
   * @returns
   */
  GetParkingLotStatusImageById = (plId: number, tenantId: number) => {
    this.statusImageByIdState.inProgress = true;
    const url =
      URLConstants.GetParkingLotStatusImageById +
      "?plid=" +
      plId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.statusImageByIdState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.parkingLotStatusImage = response.data.Data;
          this.statusImageByIdState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.statusImageByIdState.inProgress = false;
        })
      );
  };

  /**
   * This function provides initail values to the Add  Device Form.
   * @returns Initial Device Details
   */
  get statusImageDetails(): any {
    let obj = {
      FullImage: -1,
        AvailImage: -1,
        ClosedImage: -1,
        CrowdedImage: -1,
        CategoryId: -1,
        FolderId: -1,
        IsUpdate: false,
        Width: 500,
        Height: 500,
      StatusDataAdd: [],
      StatusDataUpdate: []
    }
    if (this.parkingLotStatusImage && this.parkingLotStatusImage.length > 0) {
      let data:any = [];
      let commonFolderId:number = -1;
        this.parkingLotStatusImage?.map((status:any,index:number) => {
          data.push({
            CategoryId: status.CategoryId,
            FolderId: status.FolderId,
            FullImageId: status.FullImageId,
            FullImageSource: status.FullImageSource,
            AvailImageId: status.AvailImageId,
            AvailImageSource: status.AvailImageSource,
            CloseImageId: status.CloseImageId,
            CloseImageSource: status.CloseImageSource,
            CrowdedImageId: status.CrowdedImageId,
            CrowdedImageSource: status.CrowdedImageSource,
            Height: status.Height,
            Width: status.Width
          })
        })

        obj.StatusDataUpdate = data;
        obj.IsUpdate = true;
    }
    return obj;
    // return addImageInitialState;
  }

  resetCameraSlotCount = () => {
    this.cameraSlotStatus = undefined;
    this.getCameraSlotStatusState = { ...this.initialStateValue };
  }

  resetParkingLotSlotList = () => {
    this.slotList = undefined;
    this.getCameraSlotListStatus = {...this.initialStateValue};
  }

  resetParkingLotStatusImageDetail = () => {
    this.parkingLotStatusImage = undefined;
    this.statusImageByIdState = { ...this.initialStateValue };
  };

  resetSetParkingLotStatusImageState = () => {
    this.setParkingLotStatusImageState = { ...this.initialStateValue };
  };

  resetUpdateParkingLotStatusImageState = () => {
    this.updateParkingLotStatusImageState = { ...this.initialStateValue };
  }

  resetDeleteCameraState = () => {
    this.deleteCameraState = { ...this.initialStateValue };
  };

  resetAddUpdateCameraState = () => {
    this.addUpdateCameraState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset getDeviceDetail observables to their initial values.
   * @returns
   */
  resetGetCameraDetail = () => {
    this.camera = undefined;
    this.cameraSlot = undefined;
    this.cameraState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset observables to their initial values.
   * @returns
   */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.deleteCameraState = { ...this.initialStateValue };
    this.addUpdateCameraState = { ...this.initialStateValue };
    this.changePasswordState = { ...this.initialStateValue };
  };

  /**
   * This function is used to reset all store observables to their initial values.
   * @returns
   */
  resetStore = () => {
    this.error = "";
    this.inProgress = false;
    this.cameraList = allCameraInitialState;
    this.camera = undefined;
    this.addUpdateCameraState = { ...this.initialStateValue };
    this.deleteCameraState = { ...this.initialStateValue };
    this.cameraState = { ...this.initialStateValue };
    this.changePasswordState = { ...this.initialStateValue };
  };
}

export default new CameraStore();
