/*    
<summary>
   This class component is all about Managing Parking Lot data functionality.
   Developer:Mohammad Saquib Khan, Created Date:29-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import URLConstants from "../../constants/url-constants";
import * as baseService from "../service/base-service";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { initialState } from "../initialState/add-edit-parking-lot-state";
import { toast } from "react-hot-toast";
import { formatMessage } from "../../translations/format-message";
import { initialState as GetAllParkingLotState } from "../initialState/get-all-parking-lot-state";
import { IParkingLotState } from "../../models/state/IParkingLotState";
import {
  IParkingLotList,
  IParkingLotListVM,
  IParkingLotVM,
  IParkingLotStatusPanelVM,
  IParkingLotCategoryVM,
} from "../../models/response/IParkingLotResponse";
import { getTenantIdFromLocalStorage } from "../../helpers/localstorage-helper";
import { parkingLotStatus } from "../../constants/enums/parking-lot-status-enum";
import { isOperationPermittedParkingRoleBase } from "../../helpers/action-permission-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";
import preferenceStore from "../stores/preferences-store";
import categoryEnum from "../../constants/enums/category-enum";
import { slotStatus } from "../../constants/enums/slot-status-enum";

export class ParkingLotStore implements IParkingLotState, ICommonState {
  inProgress = false;
  error = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  parkingLotList: IParkingLotListVM = GetAllParkingLotState;
  allParkingLots: IParkingLotVM[] = [];

  allParkingLotCategory: IParkingLotCategoryVM[] = [];
  getAllParkingLotCategoriesState = { ...this.initialStateValue };

  statusList: any = [];

  parkingLot: any | undefined = undefined;
  parkingLotState = { ...this.initialStateValue };

  addUpdateParkingLotState = { ...this.initialStateValue };
  deleteParkingLotState = { ...this.initialStateValue };

  setParkingLotThresholdState = { ...this.initialStateValue };

  updateParkingLotStatusState = { ...this.initialStateValue };
  getParkingLotStatusState = { ...this.initialStateValue };
  constructor() {
    makeObservable(this, {
      statusList: observable,
      parkingLot: observable,
      parkingLotState: observable,
      parkingLotList: observable,
      allParkingLots: observable,
      updateParkingLotStatusState: observable,
      addUpdateParkingLotState: observable,
      deleteParkingLotState: observable,
      setParkingLotThresholdState: observable,
      getParkingLotStatusState: observable,
      allParkingLotCategory: observable,
      getAllParkingLotCategoriesState: observable,
      GetParkingLotListService: action,
      AddParkingLotService: action,
      SetParkingLotThresholService: action,
      UpdateParkingLotThresholService: action,
      UpdateParkingLotService: action,
      GetParkingLotByIdService: action,
      UpdateParkingLotStatusService: action,
      resetAddUpdateParkingLotState: action,
      DeleteParkingLotService: action,
      GetAllParkingLotService: action,
      GetParkingLotFilterListService: action,
      GetParkingLotStatusService: action,
      GetAllParkingLotCategoryService: action,
      reset: action,
      resetParkingLotDetail: action,
      resetDeleteParkingLot: action,
      resetSetThresholdParkingLotState: action,
      resetGetParkingLotStatus: action,
      resetUpdateParkingLotStatus: action,
      parkingLotStatusListDetails: computed,
      allParkingLotList: computed,
      parkingLotDetail: computed,
      allAvailableParkingLots: computed,
      allAvailableParkingLotCategory: computed,
      allParkingCategories: computed,
      allAvailableParkingLotCategorySlotType: computed,
    });
  }

  /**
   * This function is used to Get All Parking Lots List data with pagination information by calling an API.
   * @param currentPage : Current Page Number
   * @param pagerSize : Page Size
   * @returns
   */
  GetParkingLotListService = (
    currentPage: number,
    pagerSize: number,
    tenantId: number
  ) => {
    this.inProgress = true;
    const url =
      URLConstants.ParkingLotList +
      "?PageNo=" +
      currentPage +
      "&PageSize=" +
      pagerSize +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IParkingLotListVM>>) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.parkingLotList = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  /**
   * This function is used to map userList to all Parking Lot List suitable for Grid Component.
   * @returns allParkingLotList
   */
  get allParkingLotList(): IParkingLotList[] {
    if (
      this.parkingLotList?.ParkingLot &&
      this.parkingLotList.ParkingLot?.length > 0
    )
      return this.parkingLotList.ParkingLot.map((lot) => {
        let isDelete = isOperationPermittedParkingRoleBase(
          ModuleType.ParkingManagementDeleteButton,
          "Delete"
        );
        let isEdit = isOperationPermittedParkingRoleBase(
          ModuleType.ParkingManagementList,
          "Edit"
        );
        let isCamera = lot.ParkingLotInputTypeEnum == 1 ? true : false;
        let isThreshold =
          lot.ParkingLotInputTypeEnum == 0 && lot.ParkingLotOutputTypeEnum == 0
            ? false
            : true;
        let isPriceScheduler = true;
        let isImage = true;
        let isParkingLotOpenClosed = isOperationPermittedParkingRoleBase(
          ModuleType.ParkingManagementButtons,
          "Add"
        );
        let isLinkbaseIn = lot.ParkingLotInputTypeEnum == 2 ? true : false;
        let isLinkbaseOut = lot.ParkingLotOutputTypeEnum == 1 ? true : false;
        return {
          PlId: lot.PlId,
          Tenant: lot.Tenant,
          Branch: lot.Branch,
          PlName: lot.PlName,
          ParkingLotId: lot.ParkingLotId,
          Comment: lot.Comment,
          isCamera: isCamera,
          CurrentStatus: lot.CurrentStatus,
          ParkingLotInputTypeEnum: lot.ParkingLotInputTypeEnum,
          ParkingLotOutputTypeEnum: lot.ParkingLotOutputTypeEnum,
          isEdit: isEdit,
          isDelete: isDelete,
          isThreshold: isThreshold,
          isPriceScheduler: isPriceScheduler,
          isImage: isImage,
          isParkingLotOpenClosed: isParkingLotOpenClosed,
          isLinkbaseIn: isLinkbaseIn,
          isLinkbaseOut: isLinkbaseOut,
        };
      });
    return [];
  }

  /**
   * This function is used to Get All Parking Lots List data using filter with pagination information by calling an API.
   * @param currentPage : Current Page Number
   * @param pagerSize : Page Size
   * @returns
   */
  GetParkingLotFilterListService = (
    currentPage: number,
    pagerSize: number,
    tenantId: number,
    plName: string,
    parkinglotId: string,
    currentStatus: number
  ) => {
    this.inProgress = true;
    const url =
      URLConstants.GetParkingLotFilterList +
      "?PageNo=" +
      currentPage +
      "&PageSize=" +
      pagerSize +
      "&tenantId=" +
      tenantId +
      "&plname=" +
      plName +
      "&parkinglotid=" +
      parkinglotId +
      "&currentstatus=" +
      currentStatus;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IParkingLotListVM>>) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.parkingLotList = response.data.Data;
          }
        }
      )
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  GetAllParkingLotCategoryService = (tenantId: number, plId: number) => {
    this.inProgress = true;
    const url =
      URLConstants.AllCategoryParkingLot +
      "?plid=" +
      plId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allParkingLotCategory = response.data.Data;
        }
      })
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  get allAvailableParkingLotCategory(): IOption[] {
    const parkingLotOption: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: true,
      },
    ];
    if (this.allParkingLotCategory && this.allParkingLotCategory?.length > 0)
      this.allParkingLotCategory.map((category) => {
        parkingLotOption.push({
          id: category?.Category,
          value:
            category?.Category !== 5
              ? formatMessage(categoryEnum[category?.Category]) +
                " (" +
                category.Description +
                ")"
              : formatMessage(categoryEnum[category?.Category]),
          disabled: false,
        });
      });
    return parkingLotOption;
  }

  get allAvailableParkingLotCategorySlotType(): IOption[] {
    const parkingLotOption: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: false,
      },
    ];
    if (this.allParkingLotCategory && this.allParkingLotCategory?.length > 0)
      this.allParkingLotCategory.map((category) => {
        if (category?.Category !== 5) {
          parkingLotOption.push({
            id: category?.Category,
            value:
              formatMessage(categoryEnum[category?.Category]) +
              " (" +
              category.Description +
              ")",
            disabled: false,
          });
        }
      });
    return parkingLotOption;
  }

  get allParkingCategories(): IOption[] {
    const parkingLotOption: any[] = [];
    if (this.allParkingLotCategory && this.allParkingLotCategory?.length > 0)
      this.allParkingLotCategory.map((category) => {
        parkingLotOption.push({
          Id: category?.Category,
          Description: category?.Description,
        });
      });
    return parkingLotOption;
  }

  GetAllParkingLotService = (tenantId: number) => {
    this.inProgress = true;
    const url = URLConstants.AllParkingLot + "?tenantId=" + tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.allParkingLots = response.data.Data;
        }
      })
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  get allAvailableParkingLots(): IOption[] {
    const parkingLotOption: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: false,
      },
    ];
    if (this.allParkingLots && this.allParkingLots?.length > 0)
      this.allParkingLots.map((parkingLot) => {
        parkingLotOption.push({
          id: parkingLot.PlId,
          value: parkingLot.PlName + " (" + parkingLot.ParkingLotId + ")",
          disabled: false,
          data: parkingLot.PlName,
        });
      });
    return parkingLotOption;
  }

  /**
   * This function is used to Add New Parking Lot by calling an API.
   * @param data : Parking Lot Data
   * @returns
   */
  AddParkingLotService = (data: any) => {
    this.addUpdateParkingLotState.inProgress = true;
    let url = URLConstants.AddParkingLot;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateParkingLotState.error = response.data.Message;
        } else this.addUpdateParkingLotState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateParkingLotState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateParkingLotState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Add New Parking Lot by calling an API.
   * @param data : Parking Lot Data
   * @returns
   */
  SetParkingLotThresholService = (data: any) => {
    this.setParkingLotThresholdState.inProgress = true;
    let url = URLConstants.SetParkingLotThreshold;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setParkingLotThresholdState.error = response.data.Message;
        } else this.setParkingLotThresholdState.success = true;
      })
      .catch((err: string) => {
        this.setParkingLotThresholdState.error = err;
      })
      .finally(
        action(() => {
          this.setParkingLotThresholdState.inProgress = false;
        })
      );
  };

  UpdateParkingLotThresholService = (data: any) => {
    this.setParkingLotThresholdState.inProgress = true;
    let url = URLConstants.SetParkingLotThreshold;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setParkingLotThresholdState.error = response.data.Message;
        } else this.setParkingLotThresholdState.success = true;
      })
      .catch((err: string) => {
        this.setParkingLotThresholdState.error = err;
      })
      .finally(
        action(() => {
          this.setParkingLotThresholdState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Updating Existing Parking Lot Details by calling an API.
   * @param id : The Parking Lot Identifier
   * @param data
   * @returns
   */
  UpdateParkingLotService = (data: any) => {
    this.addUpdateParkingLotState.inProgress = true;
    let url = URLConstants.ParkingLot;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateParkingLotState.error = response.data.Message;
        } else this.addUpdateParkingLotState.success = true;
      })
      .catch((err: string) => {
        this.addUpdateParkingLotState.error = err;
      })
      .finally(
        action(() => {
          this.addUpdateParkingLotState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Delete Parking Lot by calling an API.
   * @param id : The Parking Lot Identifier
   * @returns
   */
  DeleteParkingLotService = (id: number, tenantId: number) => {
    this.deleteParkingLotState.inProgress = true;
    let url =
      URLConstants.DeleteParkingLot + "?plid=" + id + "&tenantId=" + tenantId;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteParkingLotState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          // toast.success(formatMessage(response.data.Message));
          this.deleteParkingLotState.success = true;
        }
      })
      .catch((err: string) => {
        this.deleteParkingLotState.error = err;
      })
      .finally(
        action(() => {
          this.deleteParkingLotState.inProgress = false;
        })
      );
  };

  /*
    This function is used to reset all Add Parking Lot observables to their initial values.  
    */
  resetAddUpdateParkingLotState = () => {
    this.addUpdateParkingLotState.inProgress = false;
    this.addUpdateParkingLotState.success = false;
    this.addUpdateParkingLotState.error = "";
  };

  /**
   * This function is used to Get Parking Lot Details by Id by calling an API.
   * @param id : The Parking Lot Identifier
   * @returns
   */
  public GetParkingLotByIdService = (parkingId: number, tenantId: number) => {
    this.parkingLotState.inProgress = true;
    // const tenantIdFromLocalStorage = getTenantIdFromLocalStorage() != null ? getTenantIdFromLocalStorage() : -1;
    let url =
      URLConstants.GetParkingLotById +
      "?plid=" +
      parkingId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IParkingLotVM>>) => {
        if (response.data.Error) {
          this.parkingLotState.error = response.data.Message;
        } else {
          let data = response.data.Data;
          this.parkingLot = data;
        }
      })
      .catch((err: string) => {
        this.parkingLotState.error = err;
      })
      .finally(
        action(() => {
          this.parkingLotState.inProgress = false;
        })
      );
  };

  /**
   * This function provides single parking lot details
   * @returns Parking Lot Details
   */
  get parkingLotDetail() {
    let lang = preferenceStore.getLanguage;
    let category = [
      {
        Category: 1,
        Description: lang == "en" ? "Sedan" : "普通自動車",
      },
      {
        Category: 2,
        Description: lang == "en" ? "Hatchback" : "軽自動車",
      },
      {
        Category: 3,
        Description: lang == "en" ? "Handicapped" : "思いやり駐車",
      },
      {
        Category: 4,
        Description: lang == "en" ? "Motorcycles" : "バイク",
      },
    ];
    if (this.parkingLot) {
      return {
        Id: this.parkingLot.Id,
        Tenant: this.parkingLot.Tenant,
        BranchId: this.parkingLot.Branch.BranchId,
        PlName: this.parkingLot.PlName,
        ParkingLotId: this.parkingLot.ParkingLotId,
        SlotsCount: this.parkingLot.SlotsCount,
        AuthorizationKey: this.parkingLot.AuthorizationKey,
        LinkOutAddress: this.parkingLot.LinkOutAddress
          ? this.parkingLot.LinkOutAddress
          : "",
        ParkingLotInputTypeEnum: this.parkingLot.ParkingLotInputTypeEnum,
        ParkingLotOutputTypeEnum: this.parkingLot.ParkingLotOutputTypeEnum,
        CurrentStatus: this.parkingLot.CurrentStatus,
        ParkingLotsCategories:
          this.parkingLot.ParkingLotsCategoriesVM?.length > 0
            ? this.parkingLot.ParkingLotsCategoriesVM?.filter(
                (obj: any) => obj.Category !== 5
              )
            : [],
        Comment: this.parkingLot.Comment,
      };
    }
    initialState.ParkingLotsCategories = category;
    console.log(initialState);
    return initialState;
  }

  /**
   * This function is used to Updating Existing Parking Lot Details by calling an API.
   * @param id : The Parking Lot Identifier
   * @param data
   * @returns
   */
  UpdateParkingLotStatusService = (data: any) => {
    this.updateParkingLotStatusState.inProgress = true;
    let url = URLConstants.UpdateParkingLot;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.updateParkingLotStatusState.error = response.data.Message;
        } else this.updateParkingLotStatusState.success = true;
      })
      .catch((err: string) => {
        this.updateParkingLotStatusState.error = err;
      })
      .finally(
        action(() => {
          this.updateParkingLotStatusState.inProgress = false;
        })
      );
  };

  /* This function is used to show parking lot list status list. */
  GetParkingLotStatusService = (tenantId: number, plid: any) => {
    this.getParkingLotStatusState.inProgress = true;
    const url =
      URLConstants.GetParkingLotStatus +
      "?tenantId=" +
      tenantId +
      "&plid=" +
      plid;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.getParkingLotStatusState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.statusList = response.data.Data;
          this.getParkingLotStatusState.success = true;
        }
      })
      .catch((err: string) => {
        this.getParkingLotStatusState.error = err;
      })
      .finally(
        action(() => {
          this.getParkingLotStatusState.inProgress = false;
        })
      );
  };

  /* This function is used to display details of parking lot status, */

  get parkingLotStatusListDetails(): IParkingLotStatusPanelVM[] {
    if (this.statusList && this.statusList?.length > 0) {
      return this.statusList.map((data: any, index: number) => ({
        StatusId: data.StatusId,
        PlId: data.PlId,
        Category: categoryEnum[data.Category],
        Status: parkingLotStatus[data.Status],
      }));
    }
    return [];
  }

  resetGetParkingLotStatus = () => {
    this.statusList = undefined;
    this.getParkingLotStatusState = { ...this.initialStateValue };
  };

  /**
    This function is used to reset parking lot detail by id observables to their initial values.  
    */
  resetParkingLotDetail = () => {
    this.parkingLot = undefined;
    this.parkingLotState = { ...this.initialStateValue };
  };

  resetUpdateParkingLotStatus = () => {
    this.updateParkingLotStatusState = { ...this.initialStateValue };
  };

  resetDeleteParkingLot = () => {
    this.deleteParkingLotState = { ...this.initialStateValue };
  };

  resetSetThresholdParkingLotState = () => {
    this.setParkingLotThresholdState = { ...this.initialStateValue };
  };
  /*
    This function is used to reset all observables to their initial values.  
    */
  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.addUpdateParkingLotState.success = false;
    this.addUpdateParkingLotState.error = "";
    this.addUpdateParkingLotState.inProgress = false;
    this.deleteParkingLotState = { ...this.initialStateValue };
    this.parkingLotState = { ...this.initialStateValue };
    this.setParkingLotThresholdState = { ...this.initialStateValue };
  };
}

export default new ParkingLotStore();
