/*    
<summary>
   This File contains all the routes constant. 
   Developer:Mohammad Saquib Khan, Created Date:24-April-2024
</summary>
*/

import config from "../helpers/config-helper";

const appConfig = config();
const baseURL = appConfig.REACT_APP_PUBLIC_URL;
const RoutesConstants = {
  Login: baseURL + "/login",
  Logout: baseURL + "/logout",
  HucksterUser: baseURL + "/HucksterUser",
  SystemUser: baseURL + "/SystemUser",
  TenantManagement: baseURL + "/TenantManagement",
  BranchManagement: baseURL + "/BranchManagement",
  MediaManagement: baseURL + "/MediaManagement",
  ParkingLotManagement: baseURL + "/ParkingLotManagement",
  IntegratorManagement: baseURL + "/IntegratorManagement",
  CameraManagement: baseURL + "/CameraManagement",
  FoldersManagement: baseURL + "/ManageFolders",
  AccountActivation: baseURL + "/AccountActivation",
  PriceScheduler: baseURL + "/PriceScheduler",
  UserLogs: baseURL + "/UserLogs",
  SystemLogs: baseURL + "/SystemLogs",
  LinkbaseOut: baseURL + "/LinkBaseOut",
  IntegratorLinkbaseOut: baseURL + "/IntegratorLinkBaseOut",
  SoracomAuth: baseURL + "/SoracomAuths",
  ResetPassword: baseURL + "/ResetPassword"
};
export default RoutesConstants;
